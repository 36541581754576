import React from 'react';
import {Link} from "gatsby"
import {AnchorLink} from "gatsby-plugin-anchor-links";

import Layout from '../components/layout';
import Seo from "../components/seo"
import CTA from '../components/cta';

import BlogFeed from '../components/blog-feed';

import patternHex from '../images/backrounds/pattern-hex-white.jpg';
import NeedAQuote from "../components/need-a-quote";
import {StaticImage} from "gatsby-plugin-image";
import Quote from "../components/quote";
import {Card} from "react-bootstrap";


const Integration = ({height, width}) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 144 144">
    <g className="text-primary-light">
      <line x1="41.41" y1="49.4" x2="41.41" y2="98.37"
            fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10" stroke-width="3"/>
      <path d="M69.82,34.33H107V47.52" fill="none"
            stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10" stroke-width="3"/>
      <path d="M67.63,111.55H107V96.48" fill="none"
            stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10" stroke-width="3"/>
    </g>
    <g className="text-primary">
      <path
        d="M13,34.33l28.4-15.07L69.82,34.33,41.41,49.4Z" fill="none" stroke="currentColor" stroke-linecap="square"
        stroke-miterlimit="10" stroke-width="3"/>
      <rect x="82.92" y="58.82" width="48.07"
            height="26.37" fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10"
            stroke-width="3"/>
      <path
        d="M30.49,98.37H52.34c8.44,0,15.29,5.9,15.29,13.18h0c0,7.28-6.85,13.19-15.29,13.19H30.49c-8.45,0-15.3-5.91-15.3-13.19h0C15.19,104.27,22,98.37,30.49,98.37Z"
        fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10" stroke-width="3"/>
    </g>

  </svg>
)
const Shapes = ({height, width}) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 144 144">
    <g className="text-primary-light">
      <circle id="b32dafb8-e066-410c-909f-28a28b97975e" data-name="Ellipse 142" cx="104.89" cy="49.75" r="23.22"
              fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10" stroke-width="3"/>
    </g>
    <g className="text-primary">
      <rect id="ecdd0237-baa9-42c6-87d9-6fea401ebacc" data-name="Rectangle 132" x="37.17" y="80.71" width="42.57"
            height="42.57" fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10"
            stroke-width="3"/>
      <path id="ae8cb6ca-6aee-43be-814a-a161f6200135" data-name="Path 722" d="M15.89,61.36,41,20.73,66.2,61.36Z"
            fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10" stroke-width="3"/>
    </g>
  </svg>
)

const MoveIcon = ({height, width}) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 144 144">
    <g className="text-primary-light">
      <polygon points="13.16 56.2 13.16 90.6 53.16 90.6 53.16 121.81 103.17 71.8 53.56 22.19 53.56 56.2 13.16 56.2"
               fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
    </g>
    <g className="text-primary">
      <path d="M94.77,30.59h22.82a13.26,13.26,0,0,1,13.25,13.26v53.5a13.26,13.26,0,0,1-13.25,13.26H94.77" fill="none"
            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
    </g>
  </svg>
)

const Sparkle = ({height, width}) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 144 144">
    <g className="text-primary">
      <path id="a65f783b-7dd8-4f02-80cd-c83fd2ecc1c3" data-name="Path 359"
            d="M72,99.09A27.09,27.09,0,0,0,99.09,72h0a27.09,27.09,0,0,0,27.09,27.09h0" fill="none" stroke="currentColor"
            stroke-miterlimit="10" stroke-width="3"/>
      <path id="a72af404-3bb0-4059-94a9-84250632c39e" data-name="Path 360"
            d="M109.48,104.89a27,27,0,0,0-10.39,21.29A27.09,27.09,0,0,0,72,99.09h0" fill="none" stroke="currentColor"
            stroke-miterlimit="10" stroke-width="3"/>
      <path id="f3aa1b3d-af8b-41cb-b88b-168c91d07077" data-name="Path 361"
            d="M17.82,44.91A27.09,27.09,0,0,0,44.91,17.82h0A27.09,27.09,0,0,0,72,44.91h0" fill="none"
            stroke="currentColor" stroke-miterlimit="10" stroke-width="3"/>
      <path id="a0f0fd69-c41d-4f8c-adf4-53ee44e794f6" data-name="Path 366"
            d="M55.3,50.71A27,27,0,0,0,44.91,72,27.09,27.09,0,0,0,17.82,44.91h0" fill="none" stroke="currentColor"
            stroke-miterlimit="10" stroke-width="3"/>
    </g>
    <g className="text-primary-light">
      <path id="a309bdb0-07f2-4559-b000-0c2d02c6cfc5" data-name="Path 362"
            d="M91.35,35.24a17.41,17.41,0,0,0,17.41-17.42h0a17.42,17.42,0,0,0,17.42,17.42h0" fill="none"
            stroke="currentColor" stroke-miterlimit="10" stroke-width="3"/>
      <path id="efca2dee-1f38-48ef-9ad0-e1821a22cfa0" data-name="Path 363"
            d="M91.35,35.24a17.4,17.4,0,0,1,17.41,17.41h0a17.41,17.41,0,0,1,17.42-17.41h0" fill="none"
            stroke="currentColor" stroke-miterlimit="10" stroke-width="3"/>
      <path id="a04e41a8-1689-497f-80f6-d42f2496be0b" data-name="Path 364"
            d="M17.82,108.76A17.41,17.41,0,0,0,35.24,91.35h0a17.4,17.4,0,0,0,17.41,17.41h0" fill="none"
            stroke="currentColor" stroke-miterlimit="10" stroke-width="3"/>
      <path id="b642bd75-ed69-4c2b-a9de-79b52cb84615" data-name="Path 365"
            d="M17.82,108.76a17.42,17.42,0,0,1,17.42,17.42h0a17.41,17.41,0,0,1,17.41-17.42h0" fill="none"
            stroke="currentColor" stroke-miterlimit="10" stroke-width="3"/>

    </g>
  </svg>
)

const MultipleUsers = ({height, width}) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 144 144"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="27.1"
      cy="64.09"
      fill="none"
      r="10.12"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="3"
    />
    <path
      d="M9,98.88S10.74,78.4,28,78.65A16.81,16.81,0,0,1,42.61,87"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="3"
    />
    <g className="text-primary-light">
      <circle
        cx="72.1"
        cy="50.9"
        fill="none"
        r="17.3"
        stroke="currentColor"
        stroke-width="3"
      />
      <path
        d="M41.09,110.4s3-35,32.58-34.6,29.24,34.6,29.24,34.6"
        fill="none"
        stroke="currentColor"
        stroke-width="3"
      />
    </g>
    <circle
      cx="117.01"
      cy="64.09"
      fill="none"
      r="10.12"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="3"
    />
    <path
      d="M102.91,86.94a16.54,16.54,0,0,1,15-8.29c17.28.25,17.1,20.23,17.1,20.23"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="3"
    />
  </svg>
)

const DesignIcon = ({height, width}) => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 144 144">
    <g className="text-primary">
      <polyline
        points="39.69 30.59 39.69 22.8 121.11 22.8 121.11 45.45" fill="none" stroke="currentColor"
        stroke-linecap="square" stroke-miterlimit="10" stroke-width="3"/>
      <polyline
        points="121.11 105.63 121.11 121.2 39.69 121.2 39.69 102.09" fill="none" stroke="currentColor"
        stroke-linecap="square" stroke-miterlimit="10" stroke-width="3"/>
      <line x1="88.54" y1="44.74" x2="99.16"
            y2="44.74" fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10"
            stroke-width="3"/>
      <line x1="88.54" y1="61.73" x2="99.16"
            y2="61.73" fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10"
            stroke-width="3"/>
      <line x1="63.05" y1="78.73" x2="99.16"
            y2="78.73" fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10"
            stroke-width="3"/>
      <line x1="63.05" y1="95.72" x2="99.16"
            y2="95.72" fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10"
            stroke-width="3"/>
      <rect x="63.05" y="44.74"
            width="11.33" height="18.41" fill="none" stroke="currentColor" stroke-linecap="square"
            stroke-miterlimit="10" stroke-width="3"/>
      <g className="text-primary-light">
        <path d="M16.25,51.57,7.57,59.12,24.39,78"
              fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="3"/>
        <path d="M25.51,43.36,14.75,46.67l10.76,35"
              fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="3"/>
        <line x1="46.77" y1="75.19" x2="25.53"
              y2="75.19" fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10"
              stroke-width="3"/>

        <line x1="121.11" y1="53.59" x2="121.11" y2="71.29" fill="none" stroke="currentColor"
              stroke-miterlimit="10" stroke-width="3"/>
        <circle cx="121.41" cy="74.79" r="3.54" fill="none" stroke="currentColor" stroke-linecap="square"
                stroke-miterlimit="10" stroke-width="3"/>
        <polyline points="130.42 89.81 136.43 75.79 121.41 53.76 106.39 75.79 112.4 89.81" fill="none"
                  stroke="currentColor" stroke-miterlimit="10" stroke-width="3"/>
        <rect x="106.95" y="90.05" width="28.32" height="5.66" fill="none" stroke="currentColor"
              stroke-linecap="square" stroke-miterlimit="10" stroke-width="3"/>
        <rect x="25.53" y="39.08"
              width="21.24" height="53.8" fill="none" stroke="currentColor" stroke-linecap="square"
              stroke-miterlimit="10" stroke-width="3"/>
        <circle cx="36.27" cy="84.23" r="2.69"
                fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10"
                stroke-width="3"/>
      </g>
    </g>
  </svg>
);

const Index = ({location}) => (
  <Layout>
    <Seo
      pathName={location.pathname}
      title="Zendesk Guide Customization Services"
      description="729 Solutions can help you design and build a seamless Zendesk customer help center that reduces workload while providing exceptional services to customers."
    />
    <section>
      <div
        className="hero"
        style={{
          backgroundImage: `url(${patternHex})`
        }}
      >
        <div className="hero-title bg-dark-transparent">
          <div className="hero-image">
            <StaticImage
              src="../images/clients/zendesk.png"
              alt="Zendesk Implementation Premier Partner"
              layout="constrained"
              style={{ maxWidth: "200px"}}
            />
          </div>
          <h1 className="text-white">
            zendesk guide customization services
          </h1>
          <p className="text-white">
            Improve your help center efficiency by giving your customers exactly what they’re looking for without
            compromising your brand’s image.</p>
          <CTA href="/contact-us/" className="btn btn-primary" text="Tell Us About Your Project"/>
        </div>
      </div>
    </section>
    <section>
      <div className="container py-6">
        <div className="row">
          <div className="col">
            <h2>we can help customize your help center</h2>
            <p>Your customers’ support experiences start with your <Link to="/partnerships-zendesk/">Zendesk
              Knowledge Base</Link>. This is where the organization of your content and the overall design can make or
              break your brand. </p>
            <p>Our experienced designers and admins have helped many companies construct seamless and frictionless
              experiences and can help you construct an exceptional Zendesk help center experience for your
              customers.</p>
            <h2>729 Solutions’ Zendesk Guide customization service can help you with:</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <AnchorLink to="#branding">
              <Card className="p-3 custom-cards card-border text-left linked">
                <Card.Body className="p-0 text-center">
                  <DesignIcon width="150" height="150"/>
                  <h2>Zendesk Guide Branding</h2>
                  <p className="text-primary">Design a modern, sleek and easy-to-understand Zendesk help center.</p>
                </Card.Body>
              </Card>
            </AnchorLink>
          </div>
          <div className="col-lg-4">
            <AnchorLink to="#migrate">
              <Card className="p-3 custom-cards card-border text-left">
                <Card.Body className="p-0 text-center">
                  <MoveIcon width="150" height="150"/>
                  <h2>Help Center Migration</h2>
                  <p className="text-primary">Transfer over knowledge articles and FAQs from your old system.</p>
                </Card.Body>
              </Card>
            </AnchorLink>
          </div>
          <div className="col-lg-4">
            <AnchorLink to="#customize">
              <Card className="p-3 custom-cards card-border text-left">
                <Card.Body className="p-0 text-center">
                  <MultipleUsers width="150" height="150"/>
                  <h2>Customized Experience</h2>
                  <p className="text-primary">Segment users by type.</p>
                </Card.Body>
              </Card>
            </AnchorLink>
          </div>
        </div>
        <div className="row mt-6">
          <div className="col-lg-4">
            <AnchorLink to="#upgrade">
              <Card className="p-3 custom-cards card-border text-left">
                <Card.Body className="p-0 text-center">
                  <Sparkle width="150" height="150"/>
                  <h2>Upgrade Your Zendesk Theme</h2>
                  <p className="text-primary">Improve self-service and deflection where needed.</p>
                </Card.Body>
              </Card>
            </AnchorLink>
          </div>
          <div className="col-lg-4">
            <AnchorLink to="#organize">
              <Card className="p-3 custom-cards card-border text-left">
                <Card.Body className="p-0 text-center">
                  <Shapes width="150" height="150"/>
                  <h2>Organize Help Center Content</h2>
                  <p className="text-primary">Organize your content within Zendesk’s structure.</p>
                </Card.Body>
              </Card>
            </AnchorLink>
          </div>
          <div className="col-lg-4">
            <AnchorLink to="#integrate">
              <Card className="p-3 custom-cards card-border text-left">
                <Card.Body className="p-0 text-center">
                  <Integration width="150" height="150"/>
                  <h2>Help Center Integration</h2>
                  <p className="text-primary">Improve self-service and deflection Integrate with other platforms, like
                    your CRM and accounting tools.</p>
                </Card.Body>
              </Card>
            </AnchorLink>
          </div>
        </div>
      </div>
    </section>
    <section className="bg-light">
      <div className="container py-6">
        <div className="d-flex row">
          <div className="d-sm-block d-lg-none m-auto">
            <StaticImage src="../images/partners/zendesk-horizontal-guide.svg"/>
            <div className="d-flex">
              <ul className="text-primary ml-6 mb-5">
                <li>
                  <span className="text-dark">Knowledge Base</span>
                </li>
                <li>
                  <span className="text-dark">Community</span>
                </li>
                <li>
                  <span className="text-dark">Customer Portal</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="d-none d-lg-block col-lg-4 order-lg-3 mt-lg-n5">
            <StaticImage src="../images/partners/zendesk-vertical-guide.svg"/>
            <ul className="text-primary">
              <li>
                <span className="text-dark">Knowledge Base</span>
              </li>
              <li>
                <span className="text-dark">Community</span>
              </li>
              <li>
                <span className="text-dark">Customer Portal</span>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 order-lg-0">
            <StaticImage src="../images/zk-guide-design/custom-guide-knowledge-base.png"/>
            <p className="ml-2 text-muted small">Knowledge Base</p>
          </div>
          <div className="col-lg-4 order-lg-1 mt-lg-6 ml-lg-n6 mt-3">
            <StaticImage src="../images/zk-guide-design/custom-guide-community.png"/>
            <p className="ml-2 text-muted small mb-0">Community</p>
            <p className="ml-2 text-muted small">Customer Portal</p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container py-6">
        <div className="row">
          <div className="col-12 col-lg-6">
            <Quote
              quote="As a small company it is extremely important to be cost-conscious and we are pleased with the overall spending on the project."
              name="Sreedhar Reddy"
              company="Larvol"
            />
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
            <div className="w-75">
              <StaticImage src="../images/clients/larvol-logo.png"
                           alt="Larvol Logo"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="bg-light">
      <div className="container py-6">
        <div className="row">
          <div className="col">
            <h2>what do you need done to your instance?</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6" id="branding">
            <Link to="/zendesk-multibrand/">
              <Card className="p-3 custom-cards card-border text-left">
                <Card.Body className="p-0 text-center">
                  <DesignIcon width="100" height="100"/>
                  <h2>Zendesk Guide Branding</h2>
                  <p className="text-primary">
                    The first thing to consider when setting up your Zendesk help center is keeping a consistent look
                    and
                    feel so that your customers’ self-service experience does not differ from your company’s brand and
                    corporate style. Whether you have a single brand or multiple-brands, we can implement the best
                    infrastructure to manage it all.
                  </p>
                </Card.Body>
              </Card>
            </Link>
          </div>
          <div className="col-lg-6" id="organize">
            <Link to="/zendesk-implementations/">
              <Card className="p-3 custom-cards card-border text-left">
                <Card.Body className="p-0 text-center">
                  <Shapes width="100" height="100"/>
                  <h2>Organize Help Center Content</h2>
                  <p className="text-primary">
                    Your knowledge base is aimed at helping individuals actively searching for answers. One of the most
                    common issues our clients struggle with when setting up Zendesk is how to structure their content
                    logically using Zendesk Guide’s categories, sections and articles. We can help you get set up for
                    success from the start.
                  </p>
                </Card.Body>
              </Card>
            </Link>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-6" id="upgrade">
            <Link to="/zendesk-themes/">
              <Card className="p-3 custom-cards card-border text-left">
                <Card.Body className="p-0 text-center">
                  <Sparkle width="100" height="100"/>
                  <h2>Upgrade Your Zendesk Theme</h2>
                  <p className="text-primary">
                    We specialize in creating fully customized Zendesk themes to precisely fit our customers’ needs. Not
                    looking for a completely custom theme? We can help with that too. 729 can get you up and running
                    with
                    an out of the box theme, or even make modifications to existing themes to give you just the small
                    upgrade you are looking for.
                  </p>
                </Card.Body>
              </Card>
            </Link>
          </div>
          <div className="col-lg-6" id="customize">
            <Link to="/user-experience-consultant-services/">
              <Card className="p-3 custom-cards card-border text-left">
                <Card.Body className="p-0 text-center">
                  <MultipleUsers width="100" height="100"/>
                  <h2>Customized Experience</h2>
                  <p className="text-primary">
                    We can stick with the traditional Zendesk customer experience or create a custom experience for your
                    customers based on your unique goals and intent. Our masters of UI/UX can transform your help center
                    into a streamlined user experience.
                  </p>
                </Card.Body>
              </Card>
            </Link>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-6" id="migrate">
            <Link to="/zendesk-migrations/">
              <Card className="p-3 custom-cards card-border text-left">
                <Card.Body className="p-0 text-center">
                  <MoveIcon width="100" height="100"/>
                  <h2>Help Center Migration</h2>
                  <p className="text-primary">
                    Need to migrate all of your customer data from your old tool into Zendesk? We can help map the data
                    you need and make sure everything is safely moved, no matter how unique your situation. Learn more
                    about our Zendesk Migration services.
                  </p>
                </Card.Body>

              </Card>
            </Link>
          </div>
          <div className="col-lg-6" id="integrate">
            <Link to="/zendesk-integrations/">
              <Card className="p-3 custom-cards card-border text-left">
                <Card.Body className="p-0 text-center">
                  <Integration width="100" height="100"/>
                  <h2>Help Center Integration</h2>
                  <p className="text-primary">
                    With our roots in custom software development and integrations, we are fully equipped to help
                    integrate your help center with any systems that you need and properly display the information that
                    you want to pull into Zendesk. Learn more about our Zendesk Integration services.
                  </p>
                </Card.Body>
              </Card>
            </Link>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container py-6">
        <div className="row">
          <div className="col">
            <h2>sample zendesk guide designs</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <StaticImage src="../images/zk-guide-design/tiny-cards.png"
                         alt="Tiny Card"/>
          </div>
          <div className="col-lg-4">
            <StaticImage src="../images/zk-guide-design/vault-main-w-topbar.png"
                         alt="Vault TopBar"/>
          </div>
          <div className="col-lg-4">
            <StaticImage src="../images/zk-guide-design/support-center.png"
                         alt="Support Center"/>
          </div>
        </div>
      </div>
    </section>
    <section className="bg-light">
      <div className="container py-6">
        <div className="row">
          <div className="col-lg-4">
            <div className="w-75 m-auto">
              <StaticImage
                src="../images/partners/zendesk-implementation-premier-partner-badge.png"
                alt="Zendesk Implementation Premier Partner"
              />
            </div>
          </div>
          <div className="col-lg-8">
            <h2 className="text-primary-alternative text-center text-lg-left">With over 120 successful Zendesk projects under our belt, Zendesk has made 729 a Implementation Premier Partner.</h2>
          </div>
        </div>
      </div>
    </section>

    <NeedAQuote title="Request a Quote" buttonText="Get a Quote"
                text="Reach out today to learn more about creating a Zendesk Help Center." dark/>
    <BlogFeed containerClassname="py-6"/>
  </Layout>
);

export default Index;
